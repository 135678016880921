function toggleCheckboxes() {
  var doNotWorkWithLearnersCheckbox = $("#registration_form_work_with_learners_in_i_do_not_work_with_learners")[0]
  var workWithStudentInOtherCheckbox = $("#registration_form_work_with_students_in_main_other")[0]
  var otherInterestsCheckbox = $("#registration_form_interests_main_other_things")[0]
  var otherPrimaryClinicalDisciplineCheckbox = $("#registration_form_primary_clinical_discipline_main_other")[0]
  var workWithLearnersInBasicScienceCheckbox = $("#registration_form_work_with_learners_in_basic_science_courses")[0]

  if(doNotWorkWithLearnersCheckbox && doNotWorkWithLearnersCheckbox.checked) {
    $("#work-with-students-in").find('input[type=checkbox]').attr("disabled", true); 
    $("#work-with-learners-in").find('input[type=checkbox]').attr("disabled", true);
    $("#registration_form_work_with_learners_in_i_do_not_work_with_learners").attr("disabled", false);
    $("#work-with-students-in").hide();
  }

  if(workWithStudentInOtherCheckbox && workWithStudentInOtherCheckbox.checked) {
    $("#work-with-students-in-other-box").show(); 
  } else { 
    $("#work-with-students-in-other-box").hide();
  }

  if(workWithLearnersInBasicScienceCheckbox && workWithLearnersInBasicScienceCheckbox.checked) {
    $("#work-with-students-in-basic-science-options").show();
    $("#work-with-students-in-basic-science-options").find('input[type=checkbox]').attr("disabled", false);
  } else {
    $("#work-with-students-in-basic-science-options").find('input[type=checkbox]').attr("disabled", true);
    $("#work-with-students-in-basic-science-options").hide();
  }

  if(otherInterestsCheckbox && otherInterestsCheckbox.checked) {
    $("#interests-other-box").show();
  } else {
    $("#interests-other-box").hide();
  }

  if(otherPrimaryClinicalDisciplineCheckbox && otherPrimaryClinicalDisciplineCheckbox.checked) {
    $("#primary-clinical-discipline-other-box").show();
  } else {
    $("#primary-clinical-discipline-other-box").hide();
  }

  $("#registration_form_work_with_learners_in_i_do_not_work_with_learners").on('change', function (e) { 
    if(e.target.checked) {
      $("#work-with-students-in").find('input[type=checkbox]').attr("disabled", true);
      $("#work-with-learners-in").find('input[type=checkbox]').attr("disabled", true);
      $("#registration_form_work_with_learners_in_i_do_not_work_with_learners").attr("disabled", false);
      $("#work-with-students-in-other-box").prop('disabled', true)
      $("#work-with-students-in").hide();
    } else {
      $("#work-with-students-in").find('input[type=checkbox]').attr("disabled", false);
      $("#work-with-learners-in").find('input[type=checkbox]').attr("disabled", false);
      $("#work-with-students-in-other-box").prop('disabled', false)
      $("#work-with-students-in").show();
    }
  })

  $("#registration_form_work_with_learners_in_basic_science_courses").on('change', function (e) { 
    if(e.target.checked) {
      $("#work-with-students-in-basic-science-options").show();
      $("#work-with-students-in-basic-science-options").find('input[type=checkbox]').attr("disabled", false);
    } else {
      $("#work-with-students-in-basic-science-options").hide();
      $("#work-with-students-in-basic-science-options").find('input[type=checkbox]').attr("disabled", true);
    }
  })

  $("#registration_form_work_with_students_in_main_other").on('change', function (e) {
    if(e.target.checked) {
      $("#work-with-students-in-other-box").show();
      $("#work-with-students-in-other-box").prop('disabled', false)
    } else {
      $("#work-with-students-in-other-box").hide();
      $("#work-with-students-in-other-box").prop('disabled', true)
    }
  })

  $("#registration_form_interests_main_other_things").on('change', function (e) {
    if(e.target.checked) {
      $("#interests-other-box").show();
      $("#interests-other-box").prop('disabled', false)
    } else {
      $("#interests-other-box").hide();
      $("#interests-other-box").prop('disabled', true)
    }
  })

  $("#registration_form_primary_clinical_discipline_main_other").on('change', function (e) {
    if(e.target.checked) {
      $("#primary-clinical-discipline-other-box").show();
      $("#primary-clinical-discipline-other-box").prop('disabled', false)
    } else {
      $("#primary-clinical-discipline-other-box").hide();
      $("#primary-clinical-discipline-other-box").prop('disabled', true)
    }
  })
}

$(document).ready(toggleCheckboxes);
$(document).on('turbo:load', toggleCheckboxes);