function disablingOtherSelect(checkbox){
  var target = $(checkbox).attr("data-disabling-other-select-target");
  var currentState = $(checkbox).val();

  if(currentState === "Other"){
    $("#" + target).show();
  } else {
    $("#" + target).hide();
  }
}

function disablingOtherSelectReady(){
  $('.disabling-other-select').each( function(){
    disablingOtherSelect(this);
  });

  $('.disabling-other-select').change( function(){
    disablingOtherSelect(this);
  });
}

$(document).ready(disablingOtherSelectReady);
$(document).on('turbo:load', disablingOtherSelectReady);
