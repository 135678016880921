function readyMenu() {
  var menuToggle = $('#js-mobile-menu').off();
  $('#js-navigation-menu').removeClass("show");

  menuToggle.on('click', function(e) {
    e.preventDefault();
    $('#js-navigation-menu').slideToggle(function(){
      if($('#js-navigation-menu').is(':hidden')) {
        $('#js-navigation-menu').removeAttr('style');
      }
    });
  });
}

$(document).ready(readyMenu);
$(document).on('turbo:load', readyMenu);
