window.openTable = (evt, Name, Header) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(Name).style.display = "block";
    document.getElementById(Header).style.display = "block";
    evt.currentTarget.className += " active";
    localStorage.setItem("active", [Name, Header]);
}

window.pageDefault = () => {
  if (localStorage.getItem("active") != null){
    var tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    activeIds = localStorage.getItem("active").split(',')
    Name = activeIds[0];
    Header = activeIds[1];
    document.getElementById(Name).style.display = "block";
    document.getElementById(Header).style.display = "block";
    buttonName = Name + 'button'
    document.getElementById(buttonName).className += " active"
  }
  else {
    var tabcontent = document.getElementsByClassName("tablinks");
    document.getElementById('Adminbutton').className += " active"
  }
}
