import React from 'react';
import infinitePagesFactory from 'vendor/jquery.infinite-pages.js'
infinitePagesFactory($, window)

const initializeInfinitePages = () => {
  const $list = $('.infinite-list')
  $list.infinitePages({
    loading: () => {
      $list.find('.pagination-spinner').html(
        <div class="gen-loading-spinner big">
          <div class="gen-loading-spinner-bounce-1" />
          <div class="gen-loading-spinner-bounce-2" />
          <div class="gen-loading-spinner-bounce-3" />
        </div>
      )
    },
    error: () => {
      $list.button('There was an error, please try again')
    }
  })
}

$(document)
  .ready(initializeInfinitePages)
  .on('turbo:load', initializeInfinitePages)
