function disablingCheckbox(checkbox){
  var target = $(checkbox).attr("data-disabling-checkbox-target");
  var onState = $(checkbox).attr("data-disabling-checkbox-hide-state") === 'true';
  var currentState = $(checkbox).is(":checked");

  if(currentState != onState){
    $("#" + target).show();
  } else {
    $("#" + target).hide();
  }
}

function disablingCheckboxReady(){
  $('.disabling-checkbox').each( function(){
    disablingCheckbox(this);
  });

  $('.disabling-checkbox').change( function(){
    disablingCheckbox(this);
  });
}

$(document).ready(disablingCheckboxReady);
$(document).on('turbo:load', disablingCheckboxReady);
