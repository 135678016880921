function setConfirmFromSelect(select){
  var selectedOption = select.options[select.selectedIndex];
  var buttons = $(".set-confirm-from-select-button");

  if (selectedOption) {
    var confirmMessage = selectedOption.dataset.confirmMessage;
    if (confirmMessage) {
      buttons.data('confirm', confirmMessage);
    } else {
      buttons.removeData('confirm');
    }
  } else {
    buttons.removeData('confirm');
  }
}

function setConfirmFromSelectReady(){
  $('.set-confirm-from-select-select').change( function(){
    setConfirmFromSelect(this);
  });
}

$(document).ready(setConfirmFromSelectReady);
$(document).on('turbo:load', setConfirmFromSelectReady);
