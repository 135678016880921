function disablingTitleSelect(select){
  var target = $(select).attr("data-disabling-title-select-target");
  var currentState = $(select).val();

  if(currentState === "Student" || currentState === ""){
    $("#" + target).hide();
  } else {
    $("#" + target).show();
  }
}

function disablingTitleSelectReady(){
  $('.disabling-title-select').each( function(){
    disablingTitleSelect(this);
  });

  $('.disabling-title-select').change( function(){
    disablingTitleSelect(this);
  });
}

$(document).ready(disablingTitleSelectReady);
$(document).on('turbo:load', disablingTitleSelectReady);
