window.enableCollapsibleItems = () => {
  $('.usage-report .collapsible-item h1').off("click");
  $('.usage-report .collapsible-item h1').on("click", function(){
    $(this).find('.collapsed-indicator').toggle();
    $(this).find('.expanded-indicator').toggle();
    $(this).closest('.collapsible-item').find('.collapsible-content').toggle();
    if ($(this).closest('.collapsible-item').find('.remote-filler').length > 0) {
      $(this).closest('.collapsible-item').find('.remote-filler')[0].click();
    }
  });
}
