window.show_calendar  = (required) => {
  var req = required === undefined ? true : required;
  var checkbox = document.getElementById('email_checkbox');
  var details_div = document.getElementById('calendar');
  var calendar_field = document.getElementById('calendar_field');

  if(checkbox.checked) {
    details_div.style['display'] = 'block';
    calendar_field.required = req
    console.log(calendar_field.value)

    if(calendar_field.value === '') {
      calendar_field.setCustomValidity('Please select date for email invite to be sent or opt out of sending email by unchecking the box.')
    } else {
      console.log(calendar_field.value)
      calendar_field.setCustomValidity('')
    }
  } else {
    details_div.style['display'] = 'none';
    calendar_field.required = false
    calendar_field.setCustomValidity('')
  }
};
