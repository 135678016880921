function readyListSort(){
  $('.list').on('click', ':radio', function( event ) {
    var label = $(this).parent();

    if(label.hasClass('ascending') || label.hasClass('descending')) {
      label.toggleClass('ascending');
      label.toggleClass('descending');
      reverseList(label.closest('.list').find('.list-items.displayed-copy'), '.summary');
      reverseList(label.closest('.list').find('.list-items.hidden-copy'), '.summary');
    } else {
      label.siblings('.sort-option').removeClass('ascending descending');
      label.addClass('ascending');
      sortListUsingNestedText(label.closest('.list').find('.list-items.displayed-copy'), '.summary', '.' + $(this).val());
      sortListUsingNestedText(label.closest('.list').find('.list-items.hidden-copy'), '.summary', '.' + $(this).val());
    }

    event.stopImmediatePropagation();
  });
}

function sortListUsingNestedText(parent, childSelector, keySelector) {
  var items = parent.children(childSelector).sort(function(a, b) {
    var vA = $(keySelector, a).text();
    var vB = $(keySelector, b).text();
    return (vA < vB) ? -1 : (vA > vB) ? 1 : 0;
  });
  parent.append(items);
}

function reverseList(parent, childSelector) {
  var items = parent.children(childSelector).get().reverse();
  parent.append(items);
}

$(document).ready(readyListSort);
$(document).on('turbo:load', readyListSort);
