function readyListSearch() {
  $('.list').on('input', 'input', function() {
    filterList(this);
  });
}

function filterList(searchInput) {
  var lowercaseSearchValue = searchInput.value.toLowerCase();
  var list = $(searchInput).closest('.list');
  var listHiddenCopy = list.find('.list-items.hidden-copy');
  var listDisplayedCopy = list.find('.list-items.displayed-copy');
  var filteredListItems = listHiddenCopy.find('.summary').filter(function(){
    return $(this).text().toLowerCase().indexOf(lowercaseSearchValue) >= 0;
  });

  listDisplayedCopy.find('.summary').remove();
  listDisplayedCopy.append(filteredListItems.clone());
}

$(document).ready(readyListSearch);
$(document).on('turbo:load', readyListSearch);
