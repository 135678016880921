window.trackTime = Date.now()

document.onvisibilitychange = function() {
  if (document.visibilityState === 'hidden') {
    analytics.track("time_on_page", {page: window.location.href, duration_seconds: (Date.now()-window.trackTime)/1000});
  }
  window.trackTime=Date.now()
}

document.addEventListener("turbo:load", function(){
  if(window.prevUrl) {
    analytics.track("time_on_page", {page: window.prevUrl, duration_seconds: (Date.now() - window.trackTime)/1000});
    window.trackTime = Date.now()
  }
  window.prevUrl = window.location.href
})
