import datepickerFactory from 'jquery-datepicker';
datepickerFactory($)

function setUpDatePickers() {
  $('.datepicker').datepicker({
    dateFormat: "M d, yy",
    // June 1, 2017 was the launch date.
    minDate: new Date(2017, 6 - 1, 1),
    beforeShow: function (input, inst) {
      setTimeout(function () {
        inst.dpDiv.css({
          top: $(".datepicker").offset().top + 35,
          left: $(".datepicker").offset().left
        });
      }, 0);
    }
  })
  $('.studentdatepicker').datepicker({
    dateFormat: "M d, yy",
    minDate: new Date(),
  });
  $('.customcoursedatepicker1').datepicker({
    dateFormat: "M d, yy",
    minDate: new Date(),
  });
  $('.customcoursedatepicker2').datepicker({
    dateFormat: "M d, yy",
    minDate: new Date(),
  });
  $('.exam-start-date-picker-edit').datepicker({
    numberOfMonths: 2,
    dateFormat: "M d, yy",
    minDate: 0,
    onSelect: function (selected) {
      var availability_ends_at = new Date(selected);
      availability_ends_at.setDate(availability_ends_at.getDate() + 10);
      $(".exam-end-date-picker-edit").datepicker("option", "minDate", selected);
      $(".exam-end-date-picker-edit").datepicker("option", "maxDate", availability_ends_at);
      resetEndTimeAndDateFieldsEdit();
    }
  }).on('keypress', function(e){ e.preventDefault(); });

  const startDateElem = document.getElementById("exam-start-date-picker-edit");
  const startMinDate  = startDateElem && new Date(startDateElem.value);
  const startMaxDate  = startDateElem && new Date(startDateElem.value);
  $('.exam-end-date-picker-edit').datepicker({
    numberOfMonths: 2,
    dateFormat: "M d, yy",
    minDate: startMinDate,
    maxDate: startMaxDate && new Date(findMaxDate(startMaxDate)),
    onSelect: function(selected) {
      var start_date = document.getElementById("exam-start-date-picker-edit").value;
      var end_date = document.getElementById("exam-end-date-picker-edit").value;
      var needs_subset = start_date == end_date;
      rebuildEndTimeSelectOptionsEdit(needs_subset);
    }
  }).on('keypress', function(e){ e.preventDefault();});

  $('.report-dashboard-datepicker1').datepicker({
    dateFormat: "M d, yy",
    onSelect: function() {
        $("#date_changed").click();
    }
  });
  $('.report-dashboard-datepicker2').datepicker({
    dateFormat: "M d, yy",
    onSelect: function() {
        $("#date_changed").click();
    }
  });
  $('.user-profile-datepicker').datepicker({
    dateFormat: "mm-dd-yy",
    onSelect: function() {
      $("#date_changed").click();
    }
  });
}

function findMaxDate(min_date){
  return min_date.setDate(min_date.getDate()+10)
}

function resetEndTimeAndDateFields() {
  var end_date_picker = document.getElementById("exam-end-date-picker");
  end_date_picker.value = null;

  var needs_subset = false;
  rebuildEndTimeSelectOptions(needs_subset);
}

function rebuildEndTimeSelectOptions(needs_subset) {
  var start_time_select = document.getElementById("start-time-select");
  var end_time_select = document.getElementById("end-time-select");

  if (needs_subset) {
    var start_time = start_time_select.options[start_time_select.selectedIndex].text;

    // Extract subset of options after selected start_time
    var new_end_time_inner_html = start_time_select.innerHTML.slice(start_time_select.innerHTML.indexOf(start_time) - 1);
    end_time_select.innerHTML = new_end_time_inner_html;
  } else {
    // Reset time list to stock
    end_time_select.innerHTML = start_time_select.innerHTML;
  }
}

function resetEndTimeAndDateFieldsEdit() {
  var end_date_picker = document.getElementById("exam-end-date-picker-edit");
  end_date_picker.value = null;

  var needs_subset = false;
  rebuildEndTimeSelectOptions(needs_subset);
}

function rebuildEndTimeSelectOptionsEdit(needs_subset) {
  var start_time_select = document.getElementById("start-time-select");
  var end_time_select = document.getElementById("end-time-select");

  if (needs_subset) {
    var start_time = start_time_select.options[start_time_select.selectedIndex].text;

    // Extract subset of options after selected start_time
    var new_end_time_inner_html = start_time_select.innerHTML.slice(start_time_select.innerHTML.indexOf(start_time) - 1);
    end_time_select.innerHTML = new_end_time_inner_html;
  } else {
    // Reset time list to stock
    end_time_select.innerHTML = start_time_select.innerHTML;
  }
}

function setInactive(element) {
  element.disabled = true;
  element.style.background = "lightgray";
  element.style.color = "#a6a6a6";
}

function setActive(element) {
  element.disabled = false;
  element.style.background = "";
  element.style.color = "";
}

$(document).ready(setUpDatePickers);
$(document).on('turbo:load', setUpDatePickers);
