import Modal from 'modal'
import Rails from '@rails/ujs'

// The code in this file is based on https://gist.github.com/carloswherbet/0d8ea53c3d406b4abb550e736ec5d2e5
//
function setUpCustomConfirmBox() {
  window.customAlertBox = function ({title, message, okLabel}) {
    new Modal({
        title: title,
        body: message,
        buttons: [
          {
            content: okLabel || "OK",
            classes: "gen-button highlighted small",
            bindKey: 13,
            callback: function(modal){
              modal.hide();
            }
          }
        ]
    }).show();
  }

  window.customConfirmBox = function ({message, title, callback, okLabel, cancel, showsCancel = true}) {
    let buttons = []
    buttons.push({
        content: okLabel || "OK",
        classes: "gen-button highlighted small",
        bindKey: 13,
        callback: function(modal){
          modal.hide();
          callback();
        }
    })
    showsCancel && buttons.push({
      content: "Cancel",
      classes: "gen-button highlighted small",
      bindKey: false,
      callback: function(modal){
        modal.hide();
        if(cancel) {
          cancel()
        }
      }
    })

    new Modal({
        title: title || 'Please Confirm!',
        body: message,
        buttons: buttons,
        close: {
          closable: false
        }
    }).show();
  }
}

$(document).ready(setUpCustomConfirmBox);
$(document).on('turbo:load', setUpCustomConfirmBox);
