import * as intlTelInput from 'intl-tel-input';

function setupIntlTelInput() {
  var input = document.querySelector("#contact-phone-iti");
  if (!input) { return; } // Return if no contact phone field is present

  var iti = intlTelInput(input, {
    allowDropdown: true,
    initialCountry: "auto",
    geoIpLookup: function(callback) {
    $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
      var countryCode = (resp && resp.country) ? resp.country : "";
      callback(countryCode);
    });
    },
    utilsScript: "/packs/utils.js"
  });

  // on blur: validate
  input.addEventListener('blur', function() {
    $("#contact-phone-iti").val(iti.getNumber())
    if (input.value.trim()) {
      var contact_phone_validity_message = (iti.isValidNumber()) ? "" : "Provide valid contact phone number";
      input.setCustomValidity(contact_phone_validity_message);
    }
  });
}

$(document).ready(setupIntlTelInput);
$(document).on('turbo:load', setupIntlTelInput);
